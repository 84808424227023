import { graphql } from 'gatsby';
import { Location } from 'history';
import React from 'react';
import DriverConsentBaseComponent, { DriverConsentData } from 'components/FormPage/driverConsent';
import { DriverType } from 'types/forms';

type DriverConsentProps = {
  data: {
    csAddDriverDriverConsentTemplate: DriverConsentData;
  };
  location: Location;
};

const DriverConsentPage: React.FC<DriverConsentProps> = ({ data, location }) => (
  <DriverConsentBaseComponent
    data={data.csAddDriverDriverConsentTemplate}
    location={location}
    driverType={DriverType.TEMPORARY}
  />
);

export default DriverConsentPage;

export const query = graphql`
  query {
    csAddDriverDriverConsentTemplate(driver_type: { eq: "Temporary" }) {
      meta_title
      heading
    }
  }
`;
